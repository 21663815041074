import Vue from 'vue'
import Router from 'vue-router'
// import { component } from 'vue/types/umd'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const DashboardSLA = () => import('@/views/Dashboard_SLA')


const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')
const OrderDashboard = () => import('@/views/OrderDetails/Dashboard')  
const STWIncompleteOrderDashboard = () => import('@/views/OrderDetails/STWIncompleteOrderDashboard') 
const StatisticsReport =() => import('@/views/OrderDetails/StatisticsReport')
const UpdatetheOrder =() =>import('@/views/OrderDetails/UpdatetheOrderDetails')
const BlueDartTracking =() =>import('@/views/OrderDetails/Bluedarttracking')
const UPSTracking =() =>import('@/views/OrderDetails/UPSTracking')
const CreateUPSLabel =() =>import('@/views/OrderDetails/CreateUPSLabel')
const OldOrderDashboard = () => import('@/views/OrderDetails/OldDashboard')
const EditOrderDashboard = () => import('@/views/OrderDetails/editorderdetails')
const OldEditOrderDashboard = () => import('@/views/OrderDetails/oldeditorderdetails')
const AbandonedCartDashboard = () => import('@/views/AbandonedCart/dashboard')
const CustomerDashboard = () => import('@/views/Customer/Dashboard')
const customerPage = () => import('@/views/Customer/customerPage')
const CustomerRequestFrom = () => import('@/views/Customer/CustomerRequestFrom')
const CustomerInfo = () => import('@/views/Customer/CustomerInfo')
const BAUOrderConfirm =() => import('@/views/OrderDetails/BAUOrderConfirmation')
const TrackingBarcode =() => import('@/views/OrderDetails/TrackingBarcode')
const BlueDartLabel =() => import('@/views/BlueDart/BlueDartLabel')
const crtask =() => import('@/views/Task/crtask')
const TrackingMoreDashboard = () =>import('@/views/OrderDetails/trackingMoreDashboard')





const ReviewLog =() => import('@/views/OrderDetails/ReviewLog')
const EditERPOrderDetails = () => import('@/views/OrderDetails/EditERPOrderDetails')
const ERPOrderDashboard = () => import('@/views/OrderDetails/ERPOrderDashboard')
const SlaNoteBulkUpload =()=>import('@/views/OrderDetails/SlaNotesBulkUpload')
const OrderWalletAndPromoCodeDashboard =() => import('@/views/OrderDetails/OrderWalletAndPromoCodeDashboard')
const DailyReportDashboard =() => import('@/views/OrderDetails/DailyReportDashboard') 
const AbandonedCartDashboardComponent =() => import('@/views/AbandonedCart/AbandonedCartDashboardComponent') 
const AbandonedCartview =() => import('@/views/AbandonedCart/AbandonedCartview') 
const NewOrder =() => import('@/views/AbandonedCart/NewOrder')
const StwNewOrder = () => import('@/views/OrderDetails/StwNewOrder')
const DownloadOrder = () => import('@/views/OrderDetails/DownloadOrder')
const ManualOrder =() => import('@/views/AbandonedCart/ManualOrder')
const B2BLeads =() => import('@/views/AbandonedCart/B2BLeads')
const B2BLeadDashboard =() =>import('@/views/AbandonedCart/B2BLeadDetails')
const EditB2Bleads =() => import('@/views/AbandonedCart/editB2BLeads')
const orderandcartvalue =()=>import('@/views/AbandonedCart/orderandabandcarddetails')
const DeliveryStatus =()=>import('@/views/AbandonedCart/DeliveryStatus')
const CancelDeliveryStatus =()=>import('@/views/AbandonedCart/CancelDeliveryStatus')

//Views - Transactions
const Walletlist =() => import('@/views/Transactions/wallettransaction')
const RestrictWallet =() => import('@/views/Transactions/WalletRestriction')
const RestricPromocode =() =>import('@/views/Transactions/promocodeRestriction')
const RestrictReferal = () => import('@/views/Transactions/RestrictReferal')
const Rezorpaylist =() => import('@/views/Transactions/Rezorpaytrans')
const Stripelist =() => import('@/views/Transactions/Stripetranslist')
const PromoCodeDashboard = () => import('@/views/Transactions/Dashboard')
 const CreatePromoCode = () => import('@/views/Transactions/CreatePromocode')
 const PaypalList = () => import('@/views/Transactions/paypaltranslist')
 const WalletTransactionDetails =() => import('@/views/Transactions/WalletTransactionDetails')
 const CreateCustomerCouponCode = () => import('@/views/Transactions/CreateCustomerCouponCode')
 
 



// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
const PriceUpdate = () => import('@/views/ContantAPI/updateprice')
const BulkPriceUpdate = () => import('@/views/ContantAPI/bulkupdate')
const CronJobFileUpload = () => import('@/views/ContantAPI/CronJobFileUpload')
const GMCFileValidation = () => import('@/views/ContantAPI/GMCFileValidation')
const AddProductInfo =() => import('@/views/ContantAPI/AddProductInfo')
const CronStatus = () => import('@/views/ContantAPI/CronStatus')
const Bulkupload =() => import('@/views/ContantAPI/Bulkupload')
const UpdateproducType =() => import('@/views/ContantAPI/UpdateGmcProductType')
const Updateproducts =() => import('@/views/ContantAPI/UpdateGmcproduct')
const taxamazonPrice =() => import('@/views/price/taxamazonpriceinfo')
const GMCPriceUpdateLog =() => import('@/views/ContantAPI/GMCPriceUpdateLog');
const GmcLogsInfo =()  => import('@/views/ContantAPI/GmcLogs')
const GMCPriceUpdate =()  => import('@/views/ContantAPI/GMCPriceUpdate')
const ApporvelGMCBulkupload = () => import('@/views/ContantAPI/ApporvelGMCBulkupload')

const DeleteGMCProduct = () => import('@/views/ContantAPI/DeleteGMCProduct')
const UpdateProductTypes =() => import('@/views/ContantAPI/UpdateProductTypes')

const addnewbulkproduct =() => import('@/views/ContantAPI/addnewbulkproduct')
const GmcCountry =() => import('@/views/ContantAPI/GmcCountry')
// const SunskyBulk = () => import('@/views/Inventory/UploadSunsky')
const MinOrderdty =() =>import('@/views/Inventory/MinimumOrderQuantity')
const AddProduct = () => import('@/views/Inventory/addproduct')
const AddProductBeta = () => import('@/views/Inventory/addproductbeta')
const AddorDeductPrice = () => import('@/views/Inventory/AddorDeductPrice')
const EditProduct = () => import('@/views/Inventory/editproduct')
const inactiveproduct = () =>import('@/views/Inventory/inactiveproduct')
const SPAPIProduct = () =>import('@/views/Inventory/SpApiproductdetail')
//const Sample = () => import('@/views/Inventory/sample')
const GmcFilegen = () => import('@/views/price/priceinfo')
const PriceInfo = () => import('@/views/price/priceinfo')

const AmazonPriceInfo = () => import('@/views/price/amazonpriceinfo')
const EbayListing = () => import('@/views/price/ebaylisting')
const NewAmazonPriceInfo = () => import('@/views/price/newamazonpriceinfo')
const BulkAmazonPriceInfo = () => import('@/views/price/betanewamazonprinceinfo')
const DirectAmazonPriceInfo = () => import('@/views/price/directamazonpriceinfo')
const ManualAmazonPriceInfo = () => import('@/views/price/manualamazonpriceinfo')
const betaManualAmazonPriceInfo = () => import('@/views/price/betamanualamazonprice')
const RemoveProduct = () => import('@/views/Inventory/RemoveProduct')
const SinglePriceUpdate = () => import('@/views/Inventory/SinglePriceUpdate')
const AddBulkProduct =() => import('@/views/Inventory/AddBulkProduct')
const NewDBbulkproduct =() => import('@/views/Inventory/newDBbulkproduct')
const DBBulkProductAccount2 =() => import('@/views/Inventory/dbbulkuploadaccount2')
const DBBulkProductSPAPI =() => import('@/views/Inventory/dbbulkuploadspapi')
const EBaybulkscarpdata =() => import('@/views/Inventory/Ebayscrapbulkdata')
const EbaybulkUpload =()=> import('@/views/Inventory/Ebaybulkupload')
const EBaybulkscarpdata2 =() => import('@/views/Inventory/EbayScrapbulkdata(max)')
const EbaybulkUpload2 =()=> import('@/views/Inventory/EbayBulkupload(max)')
// const EBaybulkscarpdata =() => import('@/views/Inventory/Ebayscrapbulkdata')
const BetaAmazonPriceInfo = () => import('@/views/price/betaamazonpriceinfo')
const BetaeBayPriceInfo = () => import('@/views/price/ebaypriceinfo')
const DealofDayProduct = () =>import('@/views/Inventory/dealoftheday')
const checkDuplicateAsin = () =>import('@/views/Inventory/checkDuplicateAsin')
const GenericUpload = () =>import('@/views/Inventory/GenericUpload')


const DirectAmazonAndEbayPriceAndAvailability = () => import('@/views/price/DirectAmazonAndEbayPriceAndAvailability')

// const ImageUpload = () => import('@/views/Inventory/ImageUpload')
const AsinImageUpload = () => import('@/views/Inventory/AsinImageUpload')
const ProductTitleCleanup =() => import('@/views/Inventory/ProductTitlecleanup')
const AddKeyword = () => import('@/views/Inventory/AddKeyword')
const EditKeyword = () => import('@/views/Inventory/EditKeyword')
const AddNonAmazonProduct = () =>  import('@/views/Inventory/AddNonAmazonProduct')
const PricingSplitUp = () =>  import('@/views/Inventory/PricingSplitUp')
const STWPricingSplitup = () => import('@/views/Inventory/STWPricingSplitup')
const InventoryAsin = () => import('@/views/Inventory/InventoryAsin')

const Scrab = () => import('@/views/Inventory/Scrab')
const ScrabSyncAccount2 = () => import('@/views/Inventory/ScrabSyncAccount2')
const ScrabSPAPI = () => import('@/views/Inventory/ScrabSPAPI')
const NewScrab = () => import('@/views/Inventory/NewScrab')
const Scrabv2 = () => import('@/views/Inventory/scrabv2')
const AmazonAsin = () => import('@/views/Inventory/AmazonAsin')
const Pincode = () => import('@/views/Inventory/pincode')
const PincodeExceptionForAsin = () => import('@/views/Inventory/PincodeExceptionForAsin')
const PincodeException = () => import('@/views/Inventory/pincodeException')
const ActiveCountryWise = () => import('@//views/Inventory/CountryWiseBulkActive')


// InventoryTwo
const FSForOtherpanel = () => import('@/views/InventoryTwo/FSForOtherPanel')
const Addsingleebay = () => import('@/views/InventoryTwo/Addsingleebayproduct')
const SSForOtherpanel =() => import('@/views/InventoryTwo/SunSkyForotherPanel')
const AddSingleSSProduct =()=>import('@/views/InventoryTwo/AddsingleSSProduct')
const AddsingleFSProduct =()=>import('@/views/InventoryTwo/AddsingleFSProduct')
const SunSkyBulkUpload =()=>import('@/views/InventoryTwo/UploadBulkSunskyProduct')
const SunskyVerifyStatus =()=>import('@/views/InventoryTwo/SunskyVerifyStatus')
const TemplateGeneration =()=>import('@/views/InventoryTwo/TemplateGeneration')
const TemplateFormation =()=>import('@/views/InventoryTwo/TemplateFormation')
const AmazonspapiPrice =() =>import('@/views/InventoryTwo/NewAmzonTempalate') 
const CalculatedPLA =() =>import('@//views/InventoryTwo/CalculatedPLA')
const DeleteBulkListingPanelSku =() =>import('@//views/InventoryTwo/DeleteBulkListingPanelSku')
const UpdateSunskyCategory =() =>import('@//views/InventoryTwo/UpdateSunskyCategory')
const SendFCMBulkNotification =() =>import('@//views/InventoryTwo/SendFCMBulkNotification')
const RainforestVariant =() =>import('@//views/InventoryTwo/Rainforestvariant')
const AsinScrapping =() =>import('@//views/InventoryTwo/AsinScarpping')
const ProductScrappingAmazonBusinessAPI =() =>import('@//views/InventoryTwo/ProductScrappingAmazonBusinessAPI')
const ClearanceSale =() =>import('@//views/InventoryTwo/ClearanceSale')
const NewArrival =() =>import('@//views/InventoryTwo/NewArrival')
const InactiveProducts = () => import('@//views/InventoryTwo/InactiveProducts')


//Dev
const ErrorLog = () => import('@/views/Dev/ErrorLog')
const CustomerPaymentInfo = () => import('@/views/Dev/CustomerpaymentInfo')
const CustomerSmsLog =() => import('@/views/Dev/SmsLogs')
const NewOrderDetails =() => import('@/views/Dev/NewOrderDetail')
const Couponcode =() => import('@/views/Dev/CouponDetails')



// Analytics
const Statistics = () => import('@/views/Analytics/statistics')
const UIAuditScope = () => import('@/views/Analytics/UIAuditScope')
const IPDetails =() => import('@/views/Analytics/IPDetails')
const ErrorStatus =() => import('@/views/Analytics/ErrorStatus')
const ProductInfo =() => import('@/views/Analytics/ProductInfo')

//website configration
const Miscinfo =() => import('@/views/Websiteconfiguration/Miscinfo')
const ChartDetails =() =>import('@/views/Websiteconfiguration/chartdetails')
const AddBrandCategoryRanking = () => import('@/views/Websiteconfiguration/AddBrandCategoryRanking')
const AddBrandCategoryProductRanking = () => import('@/views/Websiteconfiguration/AddBrandCategoryProductRanking')
const EditBrandRanking = () => import('@/views/Websiteconfiguration/EditBrandRanking')
const EditCategoryRanking = () => import('@/views/Websiteconfiguration/EditCategoryRanking')
const EditBrandCategoryProductRanking = () => import('@/views/Websiteconfiguration/EditBrandCategoryProductRanking')
const BrandCategoryRankingDashboard = () => import('@/views/Websiteconfiguration/BrandCategoryRankingDashboard')
const ProductRankingDashboard = () => import('@/views/Websiteconfiguration/ProductRankingDashboard')

const SLADashboard = () => import('@/views/SLAReport/SLADashboard')
const SLAData = () => import('@/views/SLAReport/SLAData')

//Admin
const Admin =() => import('@/views/Admin/signuprequest')
const LoginHistory = () => import('@/views/Admin/loginhistory')
const OrderTransactionLog = () =>import('@/views/Admin/ordertransactionlog')
const UpdatePriceLogs = () => import('@/views/Admin/updatepricelogs')

//Masters
const AddandRemovePanel = () =>import('@/views/Masters/AddandRemovePanel')

//Logistics
const Barcodedetails = () => import('@/views/Logistics/Barcode')
 const LabelCancellation = () => import('@/views/Logistics/labelcancel')



Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      // redirect: '/dashboard',
     redirect: '/pages/login',
        // component: Login,
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          // redirect: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'sla-dashboard',
          // redirect: '/dashboard',
          name: 'SLA OverView',
          component: SLADashboard
        },
        
        {
          path: 'dashboards',
          // redirect: '/dashboards/order-dashboard',
          name: 'Dashboards',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'order-dashboard',
              name: 'Order Dashboard',
              component: OrderDashboard
            },
            {
              path: 'order-wallet-and-promo-code-dashboard',
              name: 'Order Wallet And PromoCode',
              component: OrderWalletAndPromoCodeDashboard
            },
            {
              path:'tracking-more-dashboard',
              name:'Tracking More Dashboard',
              component:TrackingMoreDashboard,
            },
            {
              path:'bau-Order-conformation',
              name:'BAU Order Conformation',
              component:BAUOrderConfirm,
            },  
            {
              path:'stw-incomplete-order-dashboard',
              name:'STW Incomplete Order',
              component:STWIncompleteOrderDashboard,
            },
            
            {
              path: 'daily-report-dashboard',
              name: 'Daily Report Dashboard',
              component: DailyReportDashboard
            },
            {
            path:'feedback-report',
            name:'Feedback Report',
            component:DeliveryStatus      
            },
            {
            path:'cancel-order-report',
            name:'Cancel Order Report',
            component:CancelDeliveryStatus
            },
            {
              path: 'erp-order-dashboard',
              name: 'ERP Order Dashboard',
              component: ERPOrderDashboard
            },
            {
              path:'sla-notes-bulk-upload',
              name : 'SLA Note Bulk Upload',
              component:SlaNoteBulkUpload
            },
          
            {
              path: 'crtask',
              name: 'crtask',
              component: crtask
            },
            
            {
              path: 'edit-erp-order-details',
              name: 'Edit ERP Order Details',
              component: EditERPOrderDetails
            },
            {
              path: 'sla-data',
              name: 'SLA Data',
              component: SLAData
            },
           
            {
              path:'statistics-Report',
              name: 'Statistics Report',
              component: StatisticsReport

            },
            {
              path:'upadte-order-details',
              name:'Update OrderDetails',
              component: UpdatetheOrder
            },
            {
              path: 'old-order-dashboard',
              name: 'Old Order Dashboard',
              component: OldOrderDashboard
            },
           

            {
              path: 'Edit-Order',
              name: 'Edit Order Dashboard',
              component: EditOrderDashboard 
            },
            {
              path: 'Old-Edit-Order',
              name: 'Old Edit Order Dashboard',
              component: OldEditOrderDashboard 
            },
            {
             path: 'new-order',
             name: 'New Order',
             component: NewOrder
            },
            {
              path: "stw-new-order",
              name: "Stw New Order",
              component: StwNewOrder
            },
            {
              path: "download-0rder",
              name: "Download Order",
              component: DownloadOrder
            },
            {
              path: 'manual-order',
              name: 'Manual Order',
              component: ManualOrder
             },
             {
              path:"order-and-cart-total",
              name: 'Order And Cart Report',
              component: orderandcartvalue
             },
             {
              path: 'create-B2B-leads',
              name: 'B2B Leads',
              component: B2BLeads
             },
             {
              path: 'B2B-leads-dashboard',
              name: 'B2B Leads Dashboard',
              component: B2BLeadDashboard
             },
             {
              path: 'edit-b2b-leads',
              name: 'Edit B2B Leads',
              component: EditB2Bleads
             },
            {
              path: 'abandoned-cart',
              name: 'Abandoned Cart',
              component: AbandonedCartDashboard
            },
            {
              path: 'abandoned-cart-view',
              name: 'Abandoned Cart View',
              component:AbandonedCartview
            },
            {
              path: 'abandoned-cart-dashboard-component',
              name: 'Abandoned Cart Dashboard Component',
              component:AbandonedCartDashboardComponent
            },
            {
              path: 'customer-Dashboard',
              name: 'Customer Dashboard',
              component: CustomerDashboard
            },
            {
              path: 'customer-info',
              name: 'Customer Info',
              component: CustomerInfo
            },
           
            {
              path: 'customerPage',
              name: 'customerPage',
              component: customerPage
            },
             {
              path: 'Customer-Request-From',
              name: 'Customer Request From',
              component: CustomerRequestFrom
            },
            {
              path: 'review-log',
              name: 'Review Log',
              component:ReviewLog
            },
           
          ]
        },
          //Logistics
          {
            path:'logistics',
            redirect:'/logistics/barcode-details',
            name: 'Logistics',
            component: {
              render (c) { return c('router-view') }
            },
            children: [
              {
                path: 'barcode-details',
                name: 'Barcode',
                component: Barcodedetails
              },
              {
                path: 'blue-dart-label',
                name: 'Blue Dart Label',
                component: BlueDartLabel
              },
              {
              path:'cancel-bluedart-label',
              name:'BlueDart Label Cancel',
              component:LabelCancellation
              },
              
              {
                path:'tracking-barcode',
                name:'Tracking Barcode',
                component:TrackingBarcode,
              },
             
              {
                path:'bluedart-tracking',
                name: 'Bluedart Tracking Report',
                component: BlueDartTracking
  
              },
              {
                path:'ups-tracking',
                name: 'UPS Tracking',
                component: UPSTracking
  
              },
              {
                path:'create-ups-label',
                name: 'Create UPS Label',
                component: CreateUPSLabel
  
              },
              
            ]
          },
          
          
        
         //Analytics
         {
          path: 'transactions',
          redirect: '/transactions/wallet-transaction',
          name: 'Transactions',
          component: {
            render (c) { return c('router-view') }
          },
          children: [

            {
              path: 'wallet-transactions',
              name: 'Wallet Transaction',
              component: Walletlist
            },
            {
              path: 'wallet-transaction-details',
              name: 'Wallet Transaction Details',
              component: WalletTransactionDetails
            },
            {
              path: 'restrict-wallet',
              name: 'Restrict Wallet',
              component: RestrictWallet
            },
            {
              path: 'restrict-promocode',
              name: 'Restrict Promocode',
              component: RestricPromocode
            },
            {
              path: 'restrict-referal',
              name: 'Restrict Referal',
              component: RestrictReferal
            },
            {
              path: 'razorpay-transactions',
              name: 'Razorpay Transaction',
              component: Rezorpaylist
            },
            {
              path: 'stripe-transactions',
              name: 'Stripe Transaction',
              component: Stripelist

            },
              {
              path: 'promo-code-dashboard',
              name: 'Promo Code Dashboard',
              component: PromoCodeDashboard
            },
            {
              path: 'create-promo-code',
              name: 'Create Promo Code',
              component: CreatePromoCode
            },
            {
              path: 'paypal-transactions',
              name: 'Paypal-Transactions',
              component: PaypalList
            },
            {
              path: 'create-customer-coupon-code',
              name: 'Create Customer Coupon Code',
              component: CreateCustomerCouponCode
            },
          ]
        },
        {
          path: 'contantapi',
          redirect: '/contantapi/price-update',
          name: 'ContantAPI',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'price-update',
              name: 'PriceUpdate',
              component: PriceUpdate
            },
            {
              path: 'gmc-bulk-price-update',
              name: 'Bulk Price Update',
              component: BulkPriceUpdate
            },
            {
              path: 'gmc-price-update',
              name: 'GMC Price Update',
              component: GMCPriceUpdate
            },
            
            {
              path: 'Cron-Job-File-Upload',
              name: 'CronJobFileUpload',
              component: CronJobFileUpload
            },
            {
              path: 'update-product-types',
              name: 'Update Product Types',
              component: UpdateProductTypes
            },
            {
              path: 'GMC-File-Validation',
              name: 'GMC File Validation',
              component: GMCFileValidation
            },
            {
              path:'Add-Product-Info',
              name:'Add Product Info',
              component: AddProductInfo
             
             },
             {
              path:'Bulk-upload',
              name:'Bulk upload',
              component: Bulkupload
             },
             {
              path:'Update-GMC-Producttype',
              name:'Update GMC Producttype',
              component:UpdateproducType
           },
           {
            path:'Update-GMC-Products',
            name:'Update GMC Products',
            component:Updateproducts
         },
         
             {
              path:'GMC-Bulk-upload',
              name:'GMC Bulk upload',
              component: addnewbulkproduct
             },

             {
              path:'Apporvel-GMC-Bulk-upload',
              name:'Apporvel GMC Bulk upload',
              component: ApporvelGMCBulkupload
             },
             
             {
               path: 'Cron-Status',
              name: 'Cron Status',
              component: CronStatus
            },
            {
              path: 'gmc-filegen',
              name: 'GmcFilegen',
              component: GmcFilegen
            },
            {
              path: 'Delete-GMC-Products',
              name: 'Delete GMC Products',
              component: DeleteGMCProduct
            },
            {
              path: 'gmc-country',
              name: 'GmcCountry',
              component: GmcCountry
            },
            {
              path: 'Gmc-Asin',
              name: 'GMC Price Update Log',
              component: GMCPriceUpdateLog
            },
            {
              path: 'Gmc-Logs',
              name: 'GMC Logs Info',
              component: GmcLogsInfo
            },
          ]
        },
          //Listing
        {
          path:'listing',
          redirect:'/logistics/Calculated-PLA',
          name: 'Listing',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path:'Calculated-PLA',
              name:'Calculated P&A',
              component:CalculatedPLA
            },
            {
              path: 'direct-amazon-price-and-availability',
              name: 'Direct Amazon Price and Availability',
             component: DirectAmazonPriceInfo
            },
            {
              path: 'direct-amazon-and-ebay-price-and-availability',
              name: 'Direct Amazon And Ebay Price And Availability',
             component: DirectAmazonAndEbayPriceAndAvailability
            },
            {
              path:'beta-manual-amazon-price-and-availability',
              name:'Beta Manual Amazon Price and Availability',
              component:betaManualAmazonPriceInfo
            },
            {
              path:'manual-amazon-price-and-availability',
              name:'Manual Amazon Price and Availability',
              component:ManualAmazonPriceInfo
            },
            {
              path:'delete-bulk-listing-panel-sku',
              name:'Delete Bulk Listing Panel Sku',
              component:DeleteBulkListingPanelSku
            },
            {
              path: 'check-duplicate-asin',
              name: 'checkDuplicateAsin',
              component: checkDuplicateAsin
            },
            {
              path: 'add-bulk-product',
              name: 'AddBulkProduct',
              component: AddBulkProduct
            },
            {
              path:"inventory-asin",
              name:"Inventory Asin",
              component: InventoryAsin
            },
            {
              path: 'scrab-bulk-data-spapi',
              name: 'Scrab SPAPI',
              component: ScrabSPAPI
            },
            {
              path: 'scrab-bulk-data',
              name: 'Scrab',
              component: Scrab
            },
            {
              path: 'db-bulk-product',
              name: 'NewDBbulkproduct',
              component: NewDBbulkproduct
            },
            {
              path: 'scrab-bulk-data-account2',
              name: 'Scrab Account2',
              component: ScrabSyncAccount2
            },
            {
              path: 'db-bulk-product-account2',
              name: 'NewDBbulkproduct (Account 2)',
              component: DBBulkProductAccount2
            },
            {
              path: 'db-bulk-product-spapi',
              name: 'NewDBbulkproduct (SPAPI)',
              component: DBBulkProductSPAPI
            },
            {
              path: 'ebay-bulk-scarp',
              name: 'Ebay Scrap BulkData',
              component: EBaybulkscarpdata
            },
            {
              path: 'ebay-bulk-scarp2',
              name: 'Ebay Scrap BulkData(max)',
              component: EBaybulkscarpdata2
            },
            {
             path: 'ebay-bulk-Upload',
             name:'Ebay DB Bulkupload',
             component:EbaybulkUpload
            },
            {
              path: 'ebay-bulk-Upload2',
              name:'Ebay DB Bulkupload(max)',
              component:EbaybulkUpload2
             },
           
            
          ]
        },
        {
          path: 'InventoryTwo',
          redirect: '/InventoryTwo/fs-for-other-panel',
          name: 'InventoryTwo',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'ss-bulk-upload',
              name: 'SunSky Bulk Upload',
              component: SunSkyBulkUpload
            },
            {
              path:'ss-verify-status',
              name:'Sunsky Verify Status ',
              component:SunskyVerifyStatus
            },
            {
              path: 'Ebay-Single-upload',
              name: 'Add single EbayUpload',
              component: Addsingleebay
            },
            {
              path: 'add-single-fs-product',
              name: 'Add Single FS Product',
              component: AddsingleFSProduct
            },
            { 
              path:'template-genrator',
              name:'Template Generator',
              component:TemplateGeneration
            },
            { 
              path:'template-formation',
              name:'Template Formation',
              component:TemplateFormation
            },
            {
             path:'amazon-spapiprice-template',
             name:'AmazonSpapipriceTemplate',
             component:AmazonspapiPrice
            },
            {
              path: 'fs-for-other-panel',
              name: 'FS For Other Panel',
              component: FSForOtherpanel
            },
            {
              path:'ss-for-other-panel',
              name:'SS For Other Panel',
              component:SSForOtherpanel
            },
            {
              path:'add-single-ssproduct',
              name:'Add Single SS Product',
              component:AddSingleSSProduct
            },
           
          
            {
              path:'update-sunsky-category',
              name:'Update Sunsky Category',
              component:UpdateSunskyCategory
            },
            {
              path:'send-fcm-bulk-notification',
              name:'Send FCM Bulk Notification',
              component:SendFCMBulkNotification
            },
            {
              path:'scrap-variantProducts',
              name:'Scrap VariantProducts',
              component:RainforestVariant
            },
            {
              path:'asin-scrapping',
              name:'Asin Scrapping',
              component:AsinScrapping
            },
            {
              path: 'product-scrapping-amazon-business-api',
              name: 'Product Scrapping Amazon Business API',
              component: ProductScrappingAmazonBusinessAPI
            },
            {
              path:'clearance-sale',
              name:'Clearance Sale',
              component:ClearanceSale
            },
            {
              path:'new-arrival',
              name:'New Arrival',
              component:NewArrival
            },
            {
              path:'dealofthe-dayproduct',
              name:'DealOfTheDay',
              component: DealofDayProduct
            },
            {
              path:'inactive-products',
              name:'Inactive Products',
              component:InactiveProducts
            }
            
            
          ]
        },
        {
          path: 'inventory',
          redirect: '/inventory/add-product',
          name: 'Inventory',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            
            
          
            {
              path: 'add-product',
              name: 'AddProduct',
              component: AddProduct
            },
            {
              path: 'generic-upload',
              name: 'Generic Upload',
              component: GenericUpload
            },
            {
              path: 'add-or-deduct-price',
              name: 'AddorDeductPrice',
              component: AddorDeductPrice
            },
            {
              path: 'add-product-beta',
              name: 'AddProductBeta',
              component: AddProductBeta
            },
            {
              path: 'edit-product',
              name: 'EditProduct',
              component: EditProduct
            },
            {
              path: 'country-wise-bulk-active',
              name:'Country Wise Bulk Active',
              component:ActiveCountryWise,
            },
            {
              path:'inactive-product',
              name:'Inactive Product[STW]',
              component: inactiveproduct
            },
            {
              path:'spapi-productinformation',
              name:'SPAPI ProductInformation',
              component: SPAPIProduct
            },
            {
              path:'min-order-quantity',
              name:'Min Order Quantity',
              component:MinOrderdty
            },
           
             {
               path: 'price-info',
               name: 'PriceInfo',
              component: PriceInfo
             },
       
          
             {
              path: 'amazon-price-and-availability',
              name: 'Amazon Price and Availability',
              component: AmazonPriceInfo
             },
             {
              path: 'beta-eBay-price-and-availability',
              name: 'Beta eBay Price and Availability',
              component: BetaeBayPriceInfo
            },
            {
              path: 'beta-amazon-price-and-availability',
              name: 'Beta Amazon Price and Availability',
              component: BetaAmazonPriceInfo
            },
             {
              path: 'tax-amazon-price-and-availability',
              name: 'Tax Amazon Price and Availability',
              component: taxamazonPrice
            },
            {
              path: 'bulk-amazon-price-and-availability',
              name: 'Bulk Amazon Price and Availability',
              component: BulkAmazonPriceInfo
            },
             {
              path: 'new-amazon-price-and-availability',
              name: 'New Amazon Price and Availability',
             component: NewAmazonPriceInfo
            },
           
            
           

            // 
           
            {
              path:'ebay-listing',
              name:'Ebay Listing',
              component:EbayListing
            },
             {
              path: 'product-title-cleanup',
              name: 'Product Title Cleanup',
              component: ProductTitleCleanup
             },
             {
              path: 'add-keyword',
              name: 'AddKeyword',
              component: AddKeyword
             },
             {
               path: 'addnon-amazon-product',
               name: 'AddNonAmazonProduct',
               component: AddNonAmazonProduct
             },
             {
              path: 'stw-pricing-splitup',
              name: 'STWPricingSplitup',
              component: STWPricingSplitup
             },
             {
              path: 'pricing-splitUp',
              name: 'PricingSplitUp',
              component: PricingSplitUp
             },
             {
               path: 'edit-keyword',
               name: 'EditKeyword',
               component: EditKeyword
             },
            
           
           
            //  {
            //   path: 'scrab-sp-api',
            //   name: 'NewScrab',
            //   component: NewScrab
            // },
            {
              path: 'scrab-v2',
              name: 'Scrabv2',
              component: Scrabv2
            },
             {
              path: 'remove-product',
              name: 'Remove Product',
             component: RemoveProduct
            },
            {
              path: 'single-price-update',
              name: 'Single Price Update',
             component: SinglePriceUpdate
            },
            // {
            //   path: 'image-upload',
            //   name: 'Image Upload',
            //   component: ImageUpload
            // },
            {
              path: 'asin-image-upload',
              name: 'Asin Image Upload',
              component: AsinImageUpload
            },
            {
              path:'amazon-asin',
              name:'Amazon Asin',
              component: AmazonAsin
            },
           
            {
              path:'pin-code',
              name:'Pincode',
              component: Pincode
            },
            {
              path:'pincode-exception-for-asin',
              name:'Pincode Exception For Asin',
              component: PincodeExceptionForAsin
            },
            {
              path:'pincode-exception',
              name:'Pincode Exception',
              component: PincodeException
            },
          
            


         
            // { 
            //   path: 'Abandoned-Cart',
            //   name: 'Abandoned-Cart',
            //   component: OrderDashboard
            // },
            // {
            //   path: 'Customer',
            //   name: 'Customer',
            //   component: OrderDashboard
            // } 
          ]
        },
        {
          path: 'dev',
          redirect: '/dev/error-log',
          name: 'Dev',
          component: {
            render (c) { return c('router-view') }
          },
          children: [

            {
              path: 'error-log',
              name: 'Error Log',
              component: ErrorLog
            },
            {
             path:'customer-payment-info',
             name:'Customer Payment Info',
             component: CustomerPaymentInfo 
            },
            {
              path:'customer-sms-log',
              name:'Customer SmsLog',
              component: CustomerSmsLog

            },
            {
              path:'customer-order-Detail',
              name:'Customer Order Detail',
              component: NewOrderDetails

            },
            {
              path:'coupon-code-Detail',
              name:'Coupon Code Detail',
              component: Couponcode

            },
          ]
        },

        //Analytics
        {
          path: 'analytics',
          redirect: '/analytics/statistics',
          name: 'Analytics',
          component: {
            render (c) { return c('router-view') }
          },
          children: [

            {
              path: 'statistics',
              name: 'statistics',
              component: Statistics
            },
            {
              path: 'ui-audit-scope',
              name: 'UIAuditScope',
              component: UIAuditScope
            },
            
            {
              path: 'ip-details',
              name: 'IPDetails',
              component: IPDetails
            },
            {
              path: 'error-status',
              name: 'ErrorStatus',
              component: ErrorStatus
            },
            {
              path: 'product-info',
              name: 'ProductInfo',
              component: ProductInfo
            },
          ]
        },
        //website configuration
        {
          path: 'websiteconfiguration',
          redirect: '/websiteconfiguration/miscinfo',
          name: 'Websiteconfiguration',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'miscinfo',
              name: 'Miscinfo',
              component: Miscinfo
            },
           
            {
              path:'chart-details',
              name:'Chart Details',
              component: ChartDetails
            },
            {
              path: 'add-brand-category-ranking',
              name: 'Add Brand Category Ranking',
              component: AddBrandCategoryRanking
            },
            {
              path: 'add-brand-category-product-ranking',
              name: 'Add Brand Category Product Ranking',
              component: AddBrandCategoryProductRanking
            },
            {
              path: 'edit-brand-ranking',
              name: 'Edit Brand Ranking',
              component: EditBrandRanking
            },
            {
              path: 'edit-category-ranking',
              name: 'Edit Category Ranking',
              component: EditCategoryRanking
            },
            {
              path: 'edit-brand-category-product-ranking',
              name: 'Edit Brand Category Product Ranking',
              component: EditBrandCategoryProductRanking
            },
            {
              path: 'brand-category-Ranking-dashboard',
              name: 'Brand Category Ranking Dashboard',
              component: BrandCategoryRankingDashboard
            },
            {
              path: 'product-ranking-dashboard',
              name: 'Product Ranking Dashboard',
              component: ProductRankingDashboard
            },
          ]
        },
        
        // ContantAPI
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        //Admin
        {
          path: 'admin',
          redirect: '/admin/signup-request',
          name: 'Admin',
          component: {
            render (c) { return c('router-view') }
          },
          children: [

            {
              path: 'signup-request',
              name: 'signup-request',
              component: Admin
            }
            ,
            {
              path: 'login-history',
              name: 'login-history',
              component: LoginHistory
            },
            {
              path:'order-transaction-log',
              name:'Order Transaction Log',
              component:OrderTransactionLog
            },
            {
              path:'update-price-log',
              name:'Update Price Logs',
              component:UpdatePriceLogs
            }
          ]
        },
         //Masters
         {
          path:'masters',
          redirect:'/masters/add-delete-panel',
          name: 'Masters',
          component: {
            render (c) { return c('router-view') }
          },
          children: [

            {
              path: 'add-delete-panel',
              name: 'Add And Delete Panel',
              component: AddandRemovePanel
            },
            
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}