import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueApollo)

const apolloClient = new ApolloClient({
  link: createUploadLink({ uri: 'https://shoptheworldimage.digitechniq.in/graphql' }),
    // link: createUploadLink({ uri: 'graphql' }),
  cache: new InMemoryCache()
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
import 'core-js/stable'
import router from './router'

// import VueMaterial from 'vue-material'
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'

import CoreuiVue from '@coreui/vue'
import { CModal,CModalHeader ,CModalTitle ,CModalBody } from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from 'axios';
import DataTable from './VAComponents/datatable/datatable';
import DataTableCollection from './VAComponents/datatable/datatable-collection';
import DataTableColumn from './VAComponents/datatable/datatable-column';
import DataTableCell from './VAComponents/datatable/datatable-cell';
import Paginator from './VAComponents/paginator/paginator';
import Checkbox from './VAComponents/toggles/checkbox';
import chip from './VAComponents/chip/chip';
import Drag from './directives/v-drag';
import Toaster from 'v-toaster';
import VGrid from '@revolist/vue-datagrid';
import 'v-toaster/dist/v-toaster.css'
import "@/MasterStyle/MasterControl.css"
import JsonExcel from 'vue-json-excel'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
// import { AgGridVue } from "ag-grid-vue";
import VueClipboard from 'vue-clipboard2'
import Datepicker from 'vuejs-datepicker';
import VueSessionStorage from "vue-sessionstorage";
import VModal from 'vue-js-modal';
import * as XLSX from 'xlsx/xlsx.mjs';
import {Tabs, Tab} from 'vue-tabs-component';
import CoreuiVueCharts from '@coreui/vue-chartjs'
import { CChartBar } from '@coreui/vue-chartjs'
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine-dark.css';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { AgGridVue } from '@ag-grid-community/vue';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { CProgressBar } from '@coreui/vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'


// Register the required feature modules with the Grid
ModuleRegistry.registerModules([
  InfiniteRowModelModule,
  ServerSideRowModelModule,
  RowGroupingModule,
  MenuModule,
  ColumnsToolPanelModule,
]);
Vue.prototype.$log = console.log.bind(console)
// axios.defaults.baseURL = 'https://stripetest.digitechniq.in/';
 //axios.defaults.baseURL = 'https://redis.digitechniq.in/graphql/';
 //  axios.defaults.baseURL = 'https://cms.digitechniq.in/';
 //axios.defaults.baseURL = 'https://test.digitechniq.in/';
  // axios.defaults.baseURL = 'http://localhost:4000/graphql';
  axios.defaults.baseURL = 'https://service.digitechniq.in/';



Vue.use(IconsPlugin)
Vue.use(BootstrapVue)
Vue.use(VueSessionStorage);
Vue.config.productionTip = false;
Vue.use(VModal, { dialog: true })
// Vue.use(axios)
Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(Tabs);
Vue.use(Autocomplete)
Vue.use(Tab);
Vue.use(CProgressBar)
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.use(Toaster, {
  timeout: 3000
})
Vue.directive('drag', Drag);



Vue.component('datatable', DataTable)
Vue.component('v-grid', VGrid)
Vue.component('datepicker', Datepicker)
 
Vue.component('datatable-collection', DataTableCollection)
Vue.component('datatable-column', DataTableColumn)

Vue.component('CModal', CModal)
Vue.component('CModalHeader', CModalHeader)
Vue.component('CModalTitle', CModalTitle)
Vue.component('CModalBody', CModalBody)


             

Vue.component('datatable-cell', DataTableCell)
Vue.component('paginator', Paginator)
Vue.component('checkbox', Checkbox)
Vue.component('chip', chip)
Vue.component('downloadExcel', JsonExcel);
Vue.component('AgGridVue', AgGridVue);
Vue.component('XLSX', XLSX)
Vue.component('tabs', Tabs);
Vue.component('tab', Tab);
Vue.component('CProgressBar', CProgressBar)
Vue.use(CoreuiVueCharts)
Vue.component('CChartBar', CChartBar)

Vue.prototype.$log = console.log.bind(console)
 

new Vue({
  apolloProvider,
  render: h => h(App),
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
}).$mount('#app')